<template>
  <div class="">
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="cg-table relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mb-20"
          >
            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    v-if="check.show"
                    scope="col"
                    class="relative w-12 px-6 sm:w-16 sm:px-8"
                  >
                    <input
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    />
                  </th>

                  <th
                    v-for="(column, index) in columns"
                    :key="`column-${index}`"
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ _.get(column, 'label') }}
                  </th>

                  <th
                    v-if="action_col.show"
                    scope="col"
                    class="relative py-3.5 pl-3 pr-4 sm:pr-6"
                  >
                    <span class="sr-only">{{ action_col.title }}</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="row in rows" :key="`row-${row.id}`">
                  <td
                    v-if="check.show"
                    class="relative w-12 px-6 sm:w-16 sm:px-8"
                  >
                    <div
                      class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
                    ></div>

                    <input
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    />
                  </td>
                  <slot :row="row"></slot>
                </tr>
                <tr v-if="!rows.length">
                  <td
                    :colspan="spans"
                    class="whitespace-nowrap py-2 pl-3 pr-4 text-center text-sm font-medium sm:pr-6"
                  >
                    Keine Daten verfügbar.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/axios'

export default {
  name: 'CGDataTable',
  components: {},
  props: {
    columns: {
      required: true,
    },
    attr: {
      default: 'id',
    },
    options: {},
    endpoint: {
      required: true,
    },
    check: {
      type: Object,
      default() {
        return {
          show: false,
          title: null,
        }
      },
    },
    action_col: {
      type: Object,
      default() {
        return {
          show: true,
          title: null,
        }
      },
    },
  },
  data() {
    return {
      rows: [],
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    spans() {
      return this.action_col.show
        ? this.columns.length + 2
        : this.columns.length + 1
    },
  },
  methods: {
    init() {
      api.get(`${this.endpoint}`).then(response => {
        if (response.status === 200) {
          this.rows = response.data.results.map(result => ({
            ...result,
            show: false,
          }))
        }
      })
    },
    toggle() {
      this.show = false
    },
  },
  watch: {
    modal: {
      handler: function(n) {
        n.confirm && setTimeout(() => this.init(), 2000)
      },
      deep: true,
    },
  },
}
</script>

<style>
::-webkit-scrollbar {
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
  scrollbar-width: none !important; /* Firefox */
  display: none !important; /* Safari and Chrome */
}
</style>
